
/*Menu
------------------------------------*/
/*Navbar*/
.header .navbar-default .navbar-nav > .active > a {
	color: #3498db;
}

/*Navber Toggle*/
.navbar-toggle,
.navbar-default .navbar-toggle:hover, 
.navbar-default .navbar-toggle:focus {
	background: #3498db;
}

.navbar-toggle:hover {
	background: #2980b9 !important;
}

.navbar-default .navbar-toggle {
	border-color: #2980b9;
}

/*Search Box*/
.header .navbar .nav > li > a.search:hover {
	color: #3498db;
	border-bottom-color: #3498db;
}

/*Responsive @Media 
------------------------------------*/
@media (min-width: 767px) {
	/*Navbar*/
	.header .navbar-default .navbar-nav > li > a:hover,
	.header .navbar-default .navbar-nav > .active > a {
		border-bottom: solid 2px #3498db;
	}

	.header .navbar-default .navbar-nav > .open > a,
	.header .navbar-default .navbar-nav > .open > a:hover,
	.header .navbar-default .navbar-nav > .open > a:focus {
		color: #3498db;
	}

	/*Dropdown Menu*/
	.header .dropdown-menu { 
		border-top: solid 2px #3498db; 
	}

}

@media (min-width: 767px){
.header .navbar-default .navbar-nav > li > a, .header .navbar-default .navbar-nav > li > a:hover, .header .navbar-default .navbar-nav > li > a:focus, .header .navbar-default .navbar-nav > .active > a, .header .navbar-default .navbar-nav > .active > a:hover, .header .navbar-default .navbar-nav > .active > a:focus {
background: none;
}
}